@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.content {
  margin-left: 0px;
}


/* SCROLL BAR geral, LEMBRAR */

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
 
  background: linear-gradient(180deg, #8c817500, #000000,#8c817500 ); 
  border-radius: 4px;
  transition: background-color 0.3s ease, opacity 0.3s ease; 
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg,#00000010, #0F71FD, #00000010); 
  border-radius: 4px;
  opacity: 0.7; 
}

::-webkit-scrollbar-thumb:not(:hover) {
  opacity: 0.3;
}


/* SCROLL BAR DO SIDER BAR, LEMBRAR */

.custom-scroll ::-webkit-scrollbar {
  width: 9px;
}

.custom-scroll ::-webkit-scrollbar-track {
  background: none;
}

.custom-scroll ::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #8c817500, #000000, #8c817500); 
  border-radius: 2px;
  transition: background-color 0.3s ease, opacity 0.3s ease; 
}

.custom-scroll ::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #00000010, #0F71FD, #00000010); 
  border-radius: 2px;
  opacity: 0.7; 
}

.custom-scroll ::-webkit-scrollbar-thumb:not(:hover) {
  opacity: 0.3;
}
/* SCROLL BAR DO ESPECIALIDADES NO CALENDARIO, LEMBRAR */

.fc .fc-button-primary {
  background-color: #4a4a4a !important;
  border-color: #1D1D1D !important;
}

.custom-scroll-especialidades::-webkit-scrollbar {
  width: 9px;
}

.custom-scroll-especialidades::-webkit-scrollbar-track {
  background: none;
}

.custom-scroll-especialidades::-webkit-scrollbar-thumb {

  background: linear-gradient(180deg,#0F71FD, #363636); 
  border-radius: 5px;
  transition: background-color 0.3s ease, opacity 0.3s ease; 
}

.custom-scroll-especialidades::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #0F71FD, #0F71FD);
  border-radius: 5px;
  opacity: 0.7; 
}

.custom-scroll-especialidades::-webkit-scrollbar-thumb:not(:hover) {
  opacity: 0.3;
}

.sidebar-transition {
  transition: margin-left 0.3s ease, width 0.3s ease;
}








@media only screen and (max-width: 1080px) {
  .content-not-collapsed {
    margin-left: 0px;
  }
  p, text, div {
    font-size: 11px !important;
  }
  h1 {
    font-size: 34px !important;
  }
  h2 {
    font-size: 27px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  h4 {
    font-size: 16px !important;
  }
  h5 {
    font-size: 14px !important;
  }
  h6, button {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 30px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  h4 {
    font-size: 15px !important;
  }
  h5 {
    font-size: 12px !important;
  }
  h6, button {
    font-size: 11px !important;
  }
}
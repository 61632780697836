.card {
  width: 190px;
  height: 254px;
  background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
  border-radius: 20px;
  transition: all .3s;
 }
 
 .card2 {
  width: 190px;
  height: 254px;
  background-color: #1a1a1a;
  border-radius: 20px;
  transition: all .2s;
 }
 
 .card2:hover {
  transform: scale(0.98);
  border-radius: 20px;
 }
 
 .card:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
 }


.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: "#161616";
  }
  
  .logo-container {
    margin-bottom: 20px;
  }

  
  
  .login-form h1 {
    text-align: center;
  }
  
  .input-group { 
    margin-bottom: 1px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-top: 1px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .remember-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .login-button {
    background-color: orange;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: "#0F71FD";
  }
  
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: "#161616";
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .login-form h1 {
    text-align: center;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .remember-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;
  }
  
  .login-button {
    width: 100%; /* Modificado para ocupar 100% da largura */
    background-color: #0F71FD;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0F71FD;
  }

  .google-button {
    margin-top: 15px;
  }
  
  .google-login-button {
    width: 100%;
    background-color: #141414; /* Cor de fundo preto */
    color: #0F71FD; /* Cor do texto laranja */
    border: 1px solid #0F71FD; /* Borda laranja */
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .google-login-button:hover {
    background-color: #333; /* Cor de fundo preto no hover */
  }


  .next-button{
    
    margin-top: 40px;
    width: 50%;
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #0F71FD;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .selected-image{
    text-align:center;
  }
  
  .button-container {
    display: flex;
    justify-content: center;

  }
  

  